import { Family } from "@/interfaces/attributes/family";
import { familyApiProvider } from "@/providers/family-api-provider";

const initialState = () => ({
  families: null,
});

const state: any = initialState();

const mutations = {
  reset(state: any) {
    const newState: any = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setAllFamilies(state: any, payload: Array<Family>) {
    state.families = payload;
  },
};

const getters = {
  getFamilyById: (state: any) => (id: string) => {
    return state.families[id];
  },
  getAllFamilies: (state: any) => state.families,
  getAllFamiliesFilterLabeled: (state: any) => {
    const list: any[] = [];
    state.families.forEach((family: any) => {
      list.push({ key: family.id, value: family.name });
    });
    return list;
  },
  getAllSubFamiliesFilterLabeled: (state: any) => {
    const list: any[] = [];
    state.families.forEach((family: any) => {
      family.families.forEach((sub: any) => {
        list.push({ key: sub.id, value: family.name + " > " + sub.name });
      });
    });
    return list;
  },
  getAllSubFamiliesFilterNamed: (state: any) => {
    const list: any[] = [];
    state.families.forEach((family: any) => {
      family.families.forEach((sub: any) => {
        list.push({ key: sub.code, value: family.name + " > " + sub.name });
      });
    });
    return list;
  },
};

const actions = {
  reset({ commit }: any) {
    commit("reset");
  },
  async fetchAllFamilies({ commit, state }: any) {
    const families = await familyApiProvider.getAllFamilies();
    commit("setAllFamilies", families);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
