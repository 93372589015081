import CreateReceptionPage from "../pages/reception/create-reception-page.vue";
import ReceptionListPage from "../pages/reception/reception-list-page.vue";
import ReceptionDetailPage from "../pages/reception/reception-detail-page.vue";
import { AppScope } from "@/utils/app-scope";
import OrderStatusPage from "@/pages/order/order-status-page.vue";
import ReceptionStatusPage from "@/pages/reception/reception-status-page.vue";

const receptionRoutes = [
  {
    path: "/reception/create",
    name: "create-reception",
    component: CreateReceptionPage,
    meta: {
      title: "Nouvelle réception",
      access: 1,
      scope: AppScope.reception,
    },
  },
  {
    path: "/reception/status/:reception_id",
    name: "reception-status",
    component: ReceptionStatusPage,
    meta: {
      access: 1,
      scope: AppScope.reception,
    },
  },
  {
    path: "/reception/modify/:reception_id",
    name: "modify-reception",
    component: CreateReceptionPage,
    meta: {
      withoutTitle: true,
      withoutTitleRefresh: true,
      withoutBreadcrumb: true,
      withoutBreadcrumbRefresh: true,
      access: 1,
      scope: AppScope.reception,
    },
  },
  {
    path: "/reception/list",
    alias: "/reception",
    name: "reception-list",
    component: ReceptionListPage,
    meta: {
      access: 1,
    },
  },
  {
    path: "/reception/detail/:reception_id",
    name: "reception-detail",
    component: ReceptionDetailPage,
    meta: {
      withoutTitle: true,
      withoutTitleRefresh: true,
      withoutBreadcrumb: true,
      withoutBreadcrumbRefresh: true,
      access: 1,
      scope: AppScope.reception,
    },
  },
];

export default receptionRoutes;
