











































import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { orderApiProvider } from "@/providers/order-api-provider";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import { Order, OrderStatus } from "@/interfaces/order";
import OrderSelectedStockGrid from "@/components/order/order-selected-stock-grid.vue";
import ContactSearchComponent from "@/components/_shared/searchs/contact-search-component.vue";
import router from "@/router/router";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";

@Component({
  name: "order-status-page",
  computed: {
    Order() {
      return Order;
    },
    OrderStatus() {
      return OrderStatus;
    },
  },
  components: {
    OrderSelectedStockGrid,
    AppIconTextButton,
    AddressFormComponent,
    ContactSearchComponent,
  },
})
export default class OrderStatusPage extends Vue {
  order: Order | null = null;
  orderStatus: OrderStatus | null = null;

  async beforeMount() {
    this.order = await orderApiProvider.getOrder(this.$route.params.order_id);
    if (this.order) {
      this.$eventHub.$emit(
        "update-title",
        this.$t("order.detail_page_title", { code: this.order.reference })
      );
      this.breadcrumbs.push({
        text: this.$t("order.detail_page_title", { code: this.order.reference }),
      });
    } else {
      await router.push("/404");
    }
  }

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("order.list_page_title"), href: "/order/list" },
  ];

  $refs: {
    orderDetailModal: HTMLFormElement;
  };
  async onChange() {
    const response = await orderApiProvider.updateOrder(this.order);
    if (response) {
      this.order = response;
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.success,
        mainText: this.$t("order.update_validate_success_msg"),
      });
    }
  }
}
