import { Contact } from "@/interfaces/contact";
import { Warehouse } from "@/interfaces/warehouse";
import { i18n } from "@/main";
import { Company } from "@/interfaces/company";
import { Segment } from "./segment";
import { Stock } from "./stock";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";

export enum ReceptionStatus {
  draft = "000",
  created = "001",
  inProgress = "100",
  closed = "200",
}

export interface Reception {
  code: number;
  reference: string;
  id: string;
  expectedAt: string;
  taxCode: string;
  receivedQuantity: number;
  expectedQuantity: number;
  cra: string;
  brId: string;
  warehouse: Warehouse | null;
  segment: Segment | null;
  statusCode: ReceptionStatus | null;
  stocks: Array<Stock>;
  supplier: Contact | null;
  owner: Company | null;
  createdAt: string;
}

export class Reception {
  constructor(data: Partial<Reception> = {}) {
    Object.assign(this, data);
    this.stocks = data.stocks ? data.stocks.map((e: Stock) => new Stock(e)) : [];
    this.owner = data.owner ? new Company(data.owner) : null;
    this.supplier = data.supplier ? new Contact(data.supplier) : null;
    this.warehouse = data.warehouse ? new Warehouse(data.warehouse) : null;
    this.segment = data.segment ? new Segment(data.segment) : null;
    this.statusCode = data.statusCode ? Reception.statusFromCode(data.statusCode) : null;
  }

  static canModifyScope(status: ReceptionStatus) {
    return status == ReceptionStatus.draft || status == ReceptionStatus.created;
  }
  static canDeleteScope(status: ReceptionStatus) {
    const isSuperAdmin = store.getters["user/getUser"].role == UserRole.superadmin;
    return status == ReceptionStatus.draft || isSuperAdmin;
  }

  static canShowScope(status: ReceptionStatus) {
    return status != ReceptionStatus.draft;
  }

  // Shortcut for object.
  canDeleteScope() {
    return Reception.canDeleteScope(this.statusCode);
  }

  // Shortcut for object.
  canModifyScope() {
    return Reception.canModifyScope(this.statusCode);
  }

  // Shortcut for object.
  canShowScope() {
    return Reception.canShowScope(this.statusCode);
  }

  encode(): Reception {
    const data: any = { ...this };
    data.supplier = this.supplier?.id;
    data.warehouse = this.warehouse?.id;
    data.segment = this.segment?.id;
    data.owner = this.owner?.id;
    data.stocks = this.stocks?.map((stock: Stock) => stock.encode());
    return data;
  }

  static statusFromCode(statusCode: string): ReceptionStatus | null {
    switch (statusCode) {
      case "000":
        return ReceptionStatus.draft;
      case "001":
        return ReceptionStatus.created;
      case "100":
        return ReceptionStatus.inProgress;
      case "200":
        return ReceptionStatus.closed;
      default:
        return null;
    }
  }

  static stringFromStatus(statusCode: ReceptionStatus): string {
    switch (statusCode) {
      case ReceptionStatus.draft:
        return i18n.t("reception.status.000").toString();
      case ReceptionStatus.created:
        return i18n.t("reception.status.001").toString();
      case ReceptionStatus.inProgress:
        return i18n.t("reception.status.100").toString();
      case ReceptionStatus.closed:
        return i18n.t("reception.status.200").toString();
      default:
        return "";
    }
  }

  static getAllStatus() {
    return Object.values(ReceptionStatus).map((statusCode) => ({
      value: statusCode,
      text: Reception.stringFromStatus(statusCode),
    }));
  }
}
