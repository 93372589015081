















import { Component, Vue } from "vue-property-decorator";
import OrderGrid from "@/components/order/order-grid.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { orderApiProvider } from "@/providers/order-api-provider";
import { DataSource } from "@/utils/grid/datasource";
import store from "../../stores/store";
import { Order } from "@/interfaces/order";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";

@Component({
  name: "order-list-page",
  components: { OrderGrid, AppIconTextButton, ConfirmModal },
})
export default class OrderListPage extends Vue {
  datasource: DataSource;

  async beforeMount() {
    this.$route.meta.title = this.$t("order.list_page_title");
    this.datasource = DataSource.from(orderApiProvider);
  }

  $refs: {
    orderDetailModal: HTMLFormElement;
    orderGrid: HTMLFormElement;
    orderStatusModal: HTMLFormElement;
  };

  breadcrumbs = [{ text: this.$t("app.title") }, { text: this.$t("order.list_page_title") }];

  async removeOrder(data: Order) {
    await orderApiProvider.deleteOrder(data);
    if (data.id == store.getters["createOrder/getCurrentOrderId"]) {
      await store.dispatch("createOrder/reset");
    }
    this.$refs.orderGrid.onFilterChange();
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.orderGrid) {
        this.$refs.orderGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
